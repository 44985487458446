import EnvService from "./EnvService";

export default abstract class BackendService {
    private get baseUrl(): string | undefined{
        return EnvService.instance().REACT_APP_API_END_POINT();
    } 

    public performGetJsonRequest = async <TResult>(url: string, mapperFunction: (input: any) => TResult): Promise<TResult> => {
        const response = await fetch(`${this.baseUrl}${url}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        });

        const json: any = (response.ok) ? await response.json() : null;
        const mapped: TResult = mapperFunction(json);

        return mapped;
    }

    public performGetImageRequest = async (url: string): Promise<string | null> => {
        const response = await fetch(`${this.baseUrl}${url}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        });

        const blob: Blob | null = (response.ok) ? await response.blob() : null;
        return (blob) ? URL.createObjectURL( blob ) : null;
    }
}