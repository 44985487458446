import * as React from 'react';
import styles from './GigMain.module.scss';
import IGigMainProps from './IGigMainProps';
import IGigMainState from './IGigMainState';
import MomentToStringMapper from '../../mappers/MomentToStringMapper';
import { v4 as uuidv4 } from 'uuid';
import IGig from '../../model/IGig';

export default class GigMain extends React.Component<IGigMainProps, IGigMainState> {
    constructor(props: IGigMainProps) {
        super(props);
        this.state = {};
    }

    public render = (): React.ReactElement<IGigMainProps> => {
        const { gig } = this.props;

        return (
            <div className={styles.GigMain}>
                <div className={styles.GigMainGrid}>
                    {this.createGigMainRow("Datum", MomentToStringMapper.instance().map(gig?.date))}
                    {this.createGigMainRow("Ort", this.createLocation(gig))}
                    {this.createGigMainRow("Einlass", MomentToStringMapper.instance().map(gig?.entranceTime, "HH:mm"))}
                    {this.createGigMainRow("Beginn", MomentToStringMapper.instance().map(gig?.beginTime, "HH:mm"))}
                </div>
            </div>);
    }

    private createGigMainRow = (title: string, element: React.ReactNode): React.ReactElement<IGigMainProps> => {
        return (<>
            <div key={uuidv4()} >{title}:</div>
            <div key={uuidv4()} >{element}</div>
        </>)
    }

    private createLocation = (gig: IGig): React.ReactElement<IGigMainProps> => {
        const text: string = `${gig?.location?.locality}, ${gig?.location?.address}, ${gig?.location?.zipCode} ${gig?.location?.city}`;
        return ((gig?.location?.url) ? <a href={gig?.location?.url} target="_blank" rel="noreferrer">{text}</a> : <>{text}</>);
    }
}