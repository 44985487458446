import * as React from 'react';
import styles from './Live.module.scss';
import ILiveProps from './ILiveProps';
import ILiveState from './ILiveState';
import Welcome from '../Welcome/Welcome';
import Bio from '../Bio/Bio';
import Gigs from '../Gigs/Gigs';
import Footer from '../Footer/Footer';
import Social from '../Social/Social';

export default class Live extends React.Component<ILiveProps, ILiveState> {
    constructor(props: ILiveProps) {
        super(props);
        this.state = {};
    }

    public render = (): React.ReactElement<ILiveProps> => {
        return (<div className={styles.Live}>
            <div className={styles.LiveContent}>
                {/*<Navigation />*/}
                <Social />
                <Welcome {...this.props} />
                <Bio {...this.props} />
                <Gigs {...this.props} />
                {/*<Routes>
              <Route path="/" element={<Welcome {...this.props} />} />
              <Route path="/bio" element={<Bio {...this.props} />} />
              <Route path="/gigs" element={<Gigs {...this.props} />} />
            </Routes>*/}
            </div>
            <Footer />
        </div>);
    }
}