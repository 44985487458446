import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import IMarkdownProps from './IMarkdownProps';
import IMarkdownState from './IMarkdownState';
import styles from './Markdown.module.scss';
import remarkGfm from 'remark-gfm';
import remarkBreaks  from 'remark-breaks';

export default class Markdown extends React.Component<IMarkdownProps, IMarkdownState> {
    constructor(props: IMarkdownProps) {
        super(props);
        this.state = {
            content: ''
        };
    }

    public componentDidMount = async (): Promise<void> => {
        const {file} = this.props;

        const r: Response = await fetch(file);

        if(r.ok) {
            const content: string = await r.text();

            this.setState({
                content
            });
        }
    }

    public render = (): React.ReactElement<IMarkdownProps> => {
        const { content } = this.state;
        
        return <div className={`${styles.Markdown}`}>
            <ReactMarkdown children={content} remarkPlugins={[remarkGfm, remarkBreaks]}></ReactMarkdown>
        </div>
    }
}