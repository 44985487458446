import ILocation from "../model/ILocation";

export default class JsonToLocationMapper {
    public static _instance: JsonToLocationMapper | null = null;
    public static instance = (): JsonToLocationMapper => {
        if (JsonToLocationMapper._instance == null) {
            JsonToLocationMapper._instance = new JsonToLocationMapper();
        }

        return JsonToLocationMapper._instance;
    }

    public map = (value: any): ILocation => {
        return {
            locality: value?.locality,
            address: value?.address,
            zipCode: value?.zipCode,
            city: value?.city,
            url: value?.url
        } as ILocation;
    }
}