import * as linq from 'linq';
import * as React from 'react';
import styles from './Social.module.scss';
import ISocialProps from "./ISocialProps";
import { SocialIcon } from 'react-social-icons';
import ISocialState from './ISocialState';
import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";
import ISocial from '../../model/ISocial';
import SocialService from '../../services/SocialService';

export default class Social extends React.Component<ISocialProps, ISocialState> {
    constructor(props: ISocialProps) {
        super(props);
        this.state = {
            visible: false,
            socials: []
        };
    }

    public componentDidMount = async (): Promise<void> => {
        const socials: Array<ISocial> = await SocialService.instance().getSocials();

        this.setState({
            socials
        });
    }

    public render = (): React.ReactElement<ISocialProps> => {
        const { visible, socials } = this.state;

        return <div className={`${styles.Social} ${visible ? styles.Visible : styles.Hidden}`}>
            <div className={`${styles.SocialContainer}`}>
                <div className={styles.PopIn} >
                    <div className={styles.PopInButton} onClick={() => this.toggleVisible()}>
                        {visible ? <BsCaretRightFill /> : <BsCaretLeftFill />}
                    </div>
                </div>
                <div className={`${styles.SocialMenu} ${visible ? styles.Visible : styles.Hidden}`}>
                    {linq.default.from(socials).orderBy(s => s.index).select(m => this.createSocialElement(m)).toArray()}
                </div>
            </div>
        </div>
    }

    private createSocialElement = (s: ISocial): React.ReactElement<ISocialProps, string | React.JSXElementConstructor<any>> => {
        const { visible } = this.state;

        const style: React.CSSProperties = {
            width: "32px",
            height: "32px"
        };

        return (
            <SocialIcon key={s.id} className={`${styles.SocialIcon} ${visible ? styles.Visible : styles.Hidden}`} url={s.url} style={style} />
        );
    }

    private toggleVisible = (): void => {
        const { visible } = this.state;
        this.setState({
            visible: !visible
        });
    }
}