import * as linq from 'linq';
import * as React from 'react';
import IMember from '../../model/IMember';
import MemberService from '../../services/MemberService';
import IMembersProps from './IMembersProps';
import IMembersState from './IMembersState';
import styles from './Members.module.scss';
import { v4 as uuidv4 } from 'uuid';

export default class Members extends React.Component<IMembersProps, IMembersState> {
    constructor(props: IMembersProps) {
        super(props);
        this.state = {
            members: [],
            images: {}
        }
    }

    public componentDidMount = async (): Promise<void> => {
        const members: Array<IMember> = await MemberService.instance().getMembers();
        const images: { [id: number]: any } = await this.getImages(members);

        this.setState({
            members,
            images
        });
    }

    public render = (): React.ReactElement<IMembersProps> => {
        const { members } = this.state;

        return <div className={`${styles.Members}`}>
            <div className={`${styles.MemberList}`}>
                {linq.default.from(members).orderBy(m => m.index).select(m => this.createMemberElement(m)).toArray()}
            </div>
        </div >
    }

    private getImages = async (members: Array<IMember>): Promise<{ [index: number]: any }> => {
        let images: { [index: number]: string | null } = {};
        const imagePromiseArray: Array<Promise<string | null>> = linq.default.from(members).orderBy(m => m.index).select(m => MemberService.instance().getMemberImage(m.id)).toArray();
        const imageContents: Array<string | null> = await Promise.all(imagePromiseArray);

        for (let i = 0; i < imageContents.length; i++) {
            images[i] = imageContents[i];
        }

        return images;
    }

    private createMemberElement = (m: IMember): React.ReactElement<IMembersProps, string | React.JSXElementConstructor<any>> => {
        const image: string = this.state.images[m.index];

        return (<div key={uuidv4()} className={`${styles.Member}`}>
            <div className={`${styles.Avatar}`}>
                <div className={`${styles.Image}`}>
                    <img src={image} alt={m.name} />
                </div>
                <div className={`${styles.Banner}`}>
                    <div className={`${styles.Text}`}>
                        <div>{m.name}</div>
                        <div>{m.roles.join(" / ")}</div>
                    </div>
                </div>
            </div>
        </div>);
    }
}