import * as linq from 'linq';
import * as React from 'react';
import ReactImageGallery from 'react-image-gallery';
import GigService from '../../services/GigService';
import styles from './GigGallery.module.scss';
import './AdditionalClass.scss';
import IGigGalleryProps from './IGigGalleryProps';
import IGigGalleryState from './IGigGalleryState';

export default class GigGallery extends React.Component<IGigGalleryProps, IGigGalleryState> {
    constructor(props: IGigGalleryProps) {
        super(props);
        this.state = {
            images: []
        };
    }

    public componentDidMount = async (): Promise<void> => {
        const { gig, imageNames } = this.props;
        const images: Array<string | null> = await Promise.all(linq.default.from(imageNames).select(i => GigService.instance().getImage(gig.id, i)).toArray());

        this.setState({ images });
    }

    public render = (): React.ReactElement<IGigGalleryProps> => {
        const { images } = this.state;
        const items: Array<any> = linq.default.from(images).select(i => this.createImage(i)).toArray();

        return (
            <div className={styles.GigGallery}>
                {items?.length > 0 && <ReactImageGallery items={items} /> }
            </div>);
    }

    private createImage = (i: any): any => {
        return {
            "original": i,
            "thumbnail": i,
        };
    }
}