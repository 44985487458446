import * as React from 'react';
import styles from './Footer.module.scss';
import IFooterProps from './IFooterProps';

export default class Navigation extends React.Component<IFooterProps, {}> {
    public render = (): React.ReactElement<IFooterProps> => {
        return (<div className={styles.Footer}>
            <div className={styles.Container}>Fußzeile</div>
        </div>);
    }
}