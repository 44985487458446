import * as React from 'react';
import * as linq from 'linq';
import styles from './GigRunningOrder.module.scss';
import IGigRunningOrderProps from './IGigRunningOrderProps';
import IGigRunningOrderState from './IGigRunningOrderState';
import MomentToStringMapper from '../../mappers/MomentToStringMapper';
import { v4 as uuidv4 } from 'uuid';
import IEvent from '../../model/IEvent';
import DurationToStringMapper from '../../mappers/DurationToStringMapper';
import IRunningOrderItem from '../../model/IRunningOrderItem';
import moment from 'moment';

export default class GigMain extends React.Component<IGigRunningOrderProps, IGigRunningOrderState> {
    constructor(props: IGigRunningOrderProps) {
        super(props);
        this.state = {};
    }

    public render = (): React.ReactElement<IGigRunningOrderProps> => {
        const { gig } = this.props;

        return (
            <div className={styles.GigRunningOrder}>
                <div className={styles.GigRunningOrderGrid}>
                    {
                        linq.default.from(gig?.runningOrder).select(roi => this.createRunningOrderItemRow(roi, gig?.runningOrder?.indexOf(roi), gig?.runningOrder?.length)).toArray()
                    }
                </div>
            </div>);
    }

    private createRunningOrderItemRow = (runningOrderItem: IRunningOrderItem, index: number, length: number): React.ReactElement<IGigRunningOrderProps> => {
        const { gig } = this.props;
        const isLastItem: boolean = index === length - 1;
        const isFirstItem: boolean = index === 0;
        const minutes: number = (isFirstItem) ? 0 : linq.default.rangeTo(0, index - 1, 1).select(i => gig?.runningOrder[i].duration).sum();
        const startTime: moment.Moment = moment(gig?.beginTime)?.add(minutes, "minutes");
        const endTime: moment.Moment = moment(startTime)?.add(runningOrderItem?.duration, "minutes");
        const duration: moment.Duration = moment.duration(runningOrderItem?.duration, "minutes");
        const now: moment.Moment = moment();
        const isNow: boolean = now.isBetween(startTime, moment(endTime).subtract(1, "millisecond"), "minutes", "[]");
        const isSameOrAfter: boolean = now.isSameOrAfter(endTime);

        return (
            <>
                <div key={`runningOrder_indicator_${index}_${uuidv4()}`} className={this.getIndicatorClassName(isNow)} />
                <div key={`runningOrder_dot_${index}_${uuidv4()}`} className={this.getDotClassName(runningOrderItem?.event)}></div>
                <div key={`runningOrder_title_${index}_${uuidv4()}`} className={styles.GigRunningOrderGridTitle}>{MomentToStringMapper.instance().map(startTime, "HH:mm")} {runningOrderItem?.event?.valueAsString()}</div>
                <div key={uuidv4()} />
                <div key={`runningOrder_line_${index}_${uuidv4()}`} className={styles.GigRunningOrderGridLine}></div>
                <div key={`runningOrder_duration_${index}_${uuidv4()}`} className={styles.GigRunningOrderGridDuration}>{DurationToStringMapper.instance().map(duration)}</div>

                {isLastItem && <>
                    <div key={`runningOrder_indicator_${index}_${uuidv4()}`} className={this.getIndicatorClassName(isSameOrAfter)} />
                    <div key={`runningOrder_dot_${index}_${uuidv4()}`} className={styles.GigRunningOrderGridDot}></div>
                    <div key={`runningOrder_title_${index}_${uuidv4()}`} className={styles.GigRunningOrderGridTitle}>{MomentToStringMapper.instance().map(endTime, "HH:mm")} Ende</div>
                    <div key={uuidv4()} />
                    <div key={uuidv4()} />
                    <div key={uuidv4()} />
                </>}
            </>
        )
    }

    private getIndicatorClassName = (isNow: boolean): string => {
        return (isNow) ? styles.GigRunningOrderGridIndicator : "";
    }

    private getDotClassName = (event: IEvent): string => {
        switch (event?.type) {
            case "act":
                return `${styles.GigRunningOrderGridDot} ${styles.GigRunningOrderGridDotAct}`;
            case "break":
                return `${styles.GigRunningOrderGridDot} ${styles.GigRunningOrderGridDotBreak}`;
            default:
                return styles.GigRunningOrderGridDot;
        }
    }
}