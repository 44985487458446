import * as linq from 'linq';
import JsonToSocialMapper from '../mappers/JsonToSocialMapper';
import ISocial from '../model/ISocial';
import BackendService from './BackendService';

export default class SocialService extends BackendService {
    public static _instance: SocialService | null = null;
    public static instance = (): SocialService => {
        if(SocialService._instance == null) {
            SocialService._instance = new SocialService();
        }

        return SocialService._instance;
    }

    public getSocials = async (): Promise<Array<ISocial>> => {
        const socials: Array<ISocial> = await this.performGetJsonRequest<Array<ISocial>>("/socials", (json) => linq.default.from(json).select(j => JsonToSocialMapper.instance().map(j)).toArray());
        return socials;
    }
}