import moment from 'moment';

export default class MomentToStringMapper {
    public static _instance: MomentToStringMapper | null = null;
    public static instance = (): MomentToStringMapper => {
        if (MomentToStringMapper._instance == null) {
            MomentToStringMapper._instance = new MomentToStringMapper();
        }

        return MomentToStringMapper._instance;
    }

    public map = (value: moment.Moment, format: string = "DD.MM.YYYY", onInvalid: string = "--"): string => {
        return value?.isValid() ? value.format(format) : onInvalid;
    }
}