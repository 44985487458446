import * as React from 'react';
import styles from './Welcome.module.scss';
import IWelcomeProps from './IWelcomeProps';
import Page from '../Page/Page';
import md from './../../markdown/Welcome.md';
import Markdown from '../Markdown/Markdown';

export default class Welcome extends Page<IWelcomeProps, {}> {
    public renderPage(): React.ReactElement<IWelcomeProps, string | React.JSXElementConstructor<any>> {
        return (<div className={`${styles.Welcome}`}>
            <Markdown file={md} />
        </div>);
    }
}