import moment from 'moment';

export default class DurationToStringMapper {
    public static _instance: DurationToStringMapper | null = null;
    public static instance = (): DurationToStringMapper => {
        if (DurationToStringMapper._instance == null) {
            DurationToStringMapper._instance = new DurationToStringMapper();
        }

        return DurationToStringMapper._instance;
    }

    public map = (value: moment.Duration, locale: moment.LocaleSpecifier = "de", argWithSuffix?: boolean, onInvalid: string = "--"): string => {
        return value?.isValid() ? value.locale(locale).humanize(argWithSuffix, {
            d: 31,
            h: 24,
            M: 12,
            m: 60,
            s: 60,

        }) : onInvalid;
    }
}