import * as React from 'react';
import styles from './Page.module.scss';
import IPageProps from "./IPageProps";

export default abstract class Page<TProps extends IPageProps, TState> extends React.Component<TProps, TState> {
    constructor(props: TProps, state: TState) {
        super(props);
        this.state = state;
    }

    public render = (): React.ReactElement<TProps> => {
        return <div className={`${styles.Page}`}>
            {this.renderPage()}
        </div>
    }

    public abstract renderPage(): React.ReactElement<TProps>;
}