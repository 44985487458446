import { EventType } from "../model/EventType";
import { EventValue } from "../model/EventValue";
import JsonToActMapper from "./JsonToActMapper";
import JsonToBreakMapper from "./JsonToBreakMapper";

export default class JsonToEventValueMapper {
    public static _instance: JsonToEventValueMapper | null = null;
    public static instance = (): JsonToEventValueMapper => {
        if (JsonToEventValueMapper._instance == null) {
            JsonToEventValueMapper._instance = new JsonToEventValueMapper();
        }

        return JsonToEventValueMapper._instance;
    }

    public map = (type: EventType, value: EventValue): EventValue => {

        switch (type) {
            case "act":
                return JsonToActMapper.instance().map(value);
            case "break":
                return JsonToBreakMapper.instance().map(value);
            default:
                return null;
        }
    }
}