import * as linq from 'linq';
import JsonToGigMapper from '../mappers/JsonToGigMapper';
import IGig from "../model/IGig";
import BackendService from './BackendService';

export default class GigService extends BackendService {
    public static _instance: GigService | null = null;
    public static instance = (): GigService => {
        if(GigService._instance == null) {
            GigService._instance = new GigService();
        }

        return GigService._instance;
    }

    public getGigs = async (): Promise<Array<IGig>> => {
        const gigs: Array<IGig> = await this.performGetJsonRequest<Array<IGig>>("/gigs", (json) => linq.default.from(json).select(j => JsonToGigMapper.instance().map(j)).toArray());
        return gigs;
    }

    public getGig = async (key: string): Promise<IGig> => {
        const gig: IGig = await this.performGetJsonRequest<IGig>(`/gigs/${key}`, (json) => JsonToGigMapper.instance().map(json));
        return gig;
    }

    public getImageNames = async (key: string): Promise<Array<string>> => {
        const imageNames: Array<string> = await this.performGetJsonRequest<Array<string>>(`/gigs/${key}/image`, (json) => json);
        return imageNames;
    }

    public getImage = async (id: string, fileName: string): Promise<string | null> => {
        const image: string | null = await this.performGetImageRequest(`/gigs/${id}/image/${fileName}`);
        return image;
    }
}