import IMember from "../model/IMember";

export default class JsonToMemberMapper {
    public static _instance: JsonToMemberMapper | null = null;
    public static instance = (): JsonToMemberMapper => {
        if (JsonToMemberMapper._instance == null) {
            JsonToMemberMapper._instance = new JsonToMemberMapper();
        }

        return JsonToMemberMapper._instance;
    }

    public map = (value: any): IMember => {
        return {
            id: value.id,
            image: value.image,
            index: value.index,
            memberSince: value.memberSince,
            memberUntil: value.memberUntil,
            name: value.name,
            roles: value.roles
        } as IMember;
    }
}