import IRunningOrderItem from "../model/IRunningOrderItem";
import JsonToEventMapper from "./JsonToEventMapper";

export default class JsonToRunningOrderItemMapper {
    public static _instance: JsonToRunningOrderItemMapper | null = null;
    public static instance = (): JsonToRunningOrderItemMapper => {
        if (JsonToRunningOrderItemMapper._instance == null) {
            JsonToRunningOrderItemMapper._instance = new JsonToRunningOrderItemMapper();
        }

        return JsonToRunningOrderItemMapper._instance;
    }

    public map = (value: any): IRunningOrderItem => {
        return {
            duration: value?.duration,
            event: JsonToEventMapper.instance().map(value?.event)
        } as IRunningOrderItem;
    }
}