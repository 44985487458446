import * as linq from 'linq';
import * as React from 'react';
import styles from './Tabs.module.scss';
import ITabsProps from './ITabsProps';
import ITabsState from './ITabsState';
import ITab from './ITab';

export default class Tabs extends React.Component<ITabsProps, ITabsState> {
    constructor(props: ITabsProps) {
        super(props);
        this.state = {
            activeTabKey: props.activeTabKey
        };
    }

    public render = (): React.ReactElement<ITabsProps> => {
        const { tabs } = this.props;
        const { activeTabKey } = this.state;

        const activeTab: ITab | undefined = linq.default.from(tabs).where(t => t.key === activeTabKey).singleOrDefault();

        return (<div className={styles.Tabs}>
            <div className={styles.TabsBar}>
                {linq.default.from(tabs).orderBy(t => t.index).select(t => this.createTabElement(t)).toArray()}
            </div>
            <div className={styles.TabsContent}>
                {activeTab?.content}
            </div>
        </div>)
    }

    private createTabElement = (tab: ITab): React.ReactElement<ITabsProps> => {
        const { activeTabKey } = this.state;
        const className: string = (tab?.key === activeTabKey) ? `${styles.TabsBarTab} ${styles.TabsBarTabActive}` : styles.TabsBarTab;

        return (<div className={className} onClick={() => this.setActiveTab(tab)}>
            <div className={styles.TabsBarTabTitle}>{tab?.title} </div>
        </div>);
    }

    private setActiveTab = (tab: ITab): void => {
        this.setState({
            activeTabKey: tab?.key
        });
    }
}