import * as linq from 'linq';
import * as React from 'react';
import IGig from '../../model/IGig';
import GigService from '../../services/GigService';
import Page from '../Page/Page';
import styles from './Gigs.module.scss';
import IGigsProps from './IGigsProps';
import IGigsState from './IGigsState';
import md from './../../markdown/Gigs.md';
import Markdown from '../Markdown/Markdown';
import GigGrid from '../GigGrid/GigGrid';

export default class Gigs extends Page<IGigsProps, IGigsState> {
    private subtitle: any;

    public componentDidMount = async (): Promise<void> => {
        const gigs: Array<IGig> = await GigService.instance().getGigs();

        this.setState({
            gigs
        });
    }

    public renderPage(): React.ReactElement<IGigsProps, string | React.JSXElementConstructor<any>> {
        const { gigs } = this.state;

        const orderedGigs: Array<IGig> = linq.default.from(gigs).orderByDescending(g => g.date).orderByDescending(g => g.date).toArray();

        return (<div className={`${styles.Gigs}`}>
            <Markdown file={md} />
            <GigGrid gigs={orderedGigs}/>
        </div>);
    }
}