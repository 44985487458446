import ISocial from "../model/ISocial";

export default class JsonToSocialMapper {
    public static _instance: JsonToSocialMapper | null = null;
    public static instance = (): JsonToSocialMapper => {
        if (JsonToSocialMapper._instance == null) {
            JsonToSocialMapper._instance = new JsonToSocialMapper();
        }

        return JsonToSocialMapper._instance;
    }

    public map = (value: any): ISocial => {
        return {
            index: value.index,
            id: value.id,
            url: value.url
        } as ISocial;
    }
}