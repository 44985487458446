import * as React from 'react';
import styles from './Gig.module.scss';
import IGigProps from './IGigProps';
import IGigState from './IGigState';
import Tabs from '../Tabs/Tabs';
import ITab from '../Tabs/ITab';
import GigMain from '../GigMain/GigMain';
import GigRunningOrder from '../GigRunningOrder/GigRunningOrder';
import GigGallery from '../GigGallery/GigGallery';
import GigService from '../../services/GigService';

export default class Gig extends React.Component<IGigProps, IGigState> {
    constructor(props: IGigProps) {
        super(props);
        this.state = {
            gigImageNames: []
        };
    }

    public componentDidMount = async (): Promise<void> => {
        const { gig } = this.props;
        const gigImageNames: Array<string> = await GigService.instance().getImageNames(gig.id);
        this.setState({
            gigImageNames 
        });
    }

    public render = (): React.ReactElement<IGigProps> => {
        const { gig } = this.props;
        const { gigImageNames } = this.state;

        const tabs: Array<ITab> = [
            {
                index: 0,
                key: "main",
                title: "Allgemein",
                content: <GigMain gig={gig} />
            }
            
        ];

        if(gig?.runningOrder?.length > 0) {
            tabs.push({
                index: 1,
                key: "runnigOrder",
                title: "Running Order",
                content: <GigRunningOrder gig={gig} />
            });
        }

        if(gigImageNames?.length > 0) {
            tabs.push({
                index: 2,
                key: "gallery",
                title: `Galerie (${gigImageNames?.length})`,
                content: <GigGallery gig={gig} imageNames={gigImageNames} />
            });
        }

        return (<div className={styles.Gig}>
            <Tabs tabs={tabs} activeTabKey={"main"} />
        </div>);
    }
}