import * as linq from 'linq';
import * as React from 'react';
import moment from 'moment';
import IGig from '../../model/IGig';
import styles from './GigGrid.module.scss';
import IGigGridProps from './IGigGridProps';
import IGigGridState from './IGigGridState';
import { v4 as uuidv4 } from 'uuid';
import Modal from '../Modal/Modal';
import Gig from '../Gig/Gig';

export default class GigGrid extends React.Component<IGigGridProps, IGigGridState> {

    public componentDidMount = async (): Promise<void> => {
        this.setState({
            openedGig: null
        });
    }

    public render(): React.ReactElement<IGigGridProps, string | React.JSXElementConstructor<any>> {
        const { gigs } = this.props;

        return (<div className={`${styles.GigGrid}`}>
            {(gigs?.length > 0) ? <>
                {this.createHeaderRow()}
                {linq.default.from(gigs).select(g => this.createGigRow(g)).toArray()}
            </> : <>--</>}
        </div>);
    }

    private gigIsOpened = (gig: IGig): boolean => {
        const { gigs } = this.props;
        const { openedGig } = this.state;
        const gigIsOpened: boolean = !!gig && !!openedGig && gigs?.indexOf(gig) === gigs?.indexOf(openedGig);
        return gigIsOpened;
    }

    private openGigDialog = (gig: IGig): void => {
        this.setState({
            openedGig: gig
        })
    }

    private closeGigDialog = (): void => {
        this.setState({
            openedGig: null
        })
    }

    private createHeaderRow = (): React.ReactElement<IGigGridProps, string | React.JSXElementConstructor<any>> => {
        return (<>
            <div key={uuidv4()} className={`${styles.GigGridHeader} ${styles.GigGridItem}`}>Datum</div>
            <div key={uuidv4()} className={`${styles.GigGridHeader} ${styles.GigGridItem}`}>Ort</div>
            <div key={uuidv4()} className={`${styles.GigGridHeader} ${styles.GigGridItem}`}>Gig</div>
        </>);
    }

    private createGigRow = (g: IGig): React.ReactElement<IGigGridProps, string | React.JSXElementConstructor<any>> => {
        const now: moment.Moment = moment(moment.now());
        const isBefore: boolean = g.date.isBefore(now, "day");
        const classNameGigGridItemPast: string = isBefore ? ` ${styles.GigGridItemPast}` : '';
        const className: string = `${styles.GigGridItem}${classNameGigGridItemPast}`;

        return (<>
            <div key={uuidv4()} className={`${className}`}>{g?.date?.format("DD.MM.YYYY")}</div>
            <div key={uuidv4()} className={`${className}`}>{g?.location?.city}</div>
            <div key={uuidv4()} className={`${className}`}>
                <div className={`${styles.GigGridTitle}`} onClick={() => this.openGigDialog(g)}>{g.title}</div>
                <Modal key={uuidv4()} title={`${g?.title} in ${g?.location?.city} am ${g?.date.format("DD.MM.YYYY")}`} isOpen={this.gigIsOpened(g)} onOpen={() => this.openGigDialog(g)} onClose={() => this.closeGigDialog()}>
                    <Gig key={uuidv4()} gig={g} />
                </Modal>
            </div>
        </>);
    }
}