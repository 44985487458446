import * as linq from "linq";
import moment from "moment";
import IGig from "../model/IGig";
import JsonToLocationMapper from "./JsonToLocationMapper";
import JsonToRunningOrderItemMapper from "./JsonToRunningOrderItemMapper";
import StringToMomentMapper from "./StringToMomentMapper";

export default class JsonToGigMapper {
    public static _instance: JsonToGigMapper | null = null;
    public static instance = (): JsonToGigMapper => {
        if (JsonToGigMapper._instance == null) {
            JsonToGigMapper._instance = new JsonToGigMapper();
        }

        return JsonToGigMapper._instance;
    }

    public map = (value: any): IGig => {
        const date: moment.Moment = StringToMomentMapper.instance().map(value.date);
        const dateString: string = date?.format("DD.MM.YYYY");

        return {
            date,
            id: value?.id,
            title: value?.title,
            location: JsonToLocationMapper.instance().map(value?.location),
            entranceTime: StringToMomentMapper.instance().map(`${dateString} ${value?.entranceTime}`, "DD.MM.YYYY HH:mm"),
            beginTime: StringToMomentMapper.instance().map(`${dateString} ${value?.beginTime}`, "DD.MM.YYYY HH:mm"),
            runningOrder: linq.default.from(value?.runningOrder).select(roi => JsonToRunningOrderItemMapper.instance().map(roi)).toArray()
        } as IGig;
    }
}