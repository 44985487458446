import * as React from 'react';
import Page from '../Page/Page';
import styles from './Bio.module.scss';
import IBioProps from './IBioProps';
import md from './../../markdown/Bio.md';
import Markdown from '../Markdown/Markdown';
import Members from '../Members/Members';

export default class Menu extends Page<IBioProps, {}> {
    public renderPage(): React.ReactElement<IBioProps, string | React.JSXElementConstructor<any>> {
        return (<div className={`${styles.Bio}`}>
            <Markdown file={md} />
            <Members />
        </div>);
    }
}