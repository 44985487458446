import IBreak from "../model/IBreak";

export default class JsonToBreakMapper {
    public static _instance: JsonToBreakMapper | null = null;
    public static instance = (): JsonToBreakMapper => {
        if (JsonToBreakMapper._instance == null) {
            JsonToBreakMapper._instance = new JsonToBreakMapper();
        }

        return JsonToBreakMapper._instance;
    }

    public map = (value: any): IBreak => {
        return {
            kind: value.kind
        } as IBreak;
    }
}