import IAct from "../model/IAct";

export default class JsonToActMapper {
    public static _instance: JsonToActMapper | null = null;
    public static instance = (): JsonToActMapper => {
        if (JsonToActMapper._instance == null) {
            JsonToActMapper._instance = new JsonToActMapper();
        }

        return JsonToActMapper._instance;
    }

    public map = (value: any): IAct => {
        return {
            name: value.name
        } as IAct;
    }
}