import * as React from 'react';
import styles from './Modal.module.scss';
import IModalProps from './IModalProps';
import IModalState from './IModalState';

export default class Modal extends React.Component<IModalProps, IModalState> {
    constructor(props: IModalProps) {
        super(props);
        this.state = {
            isOpen: props.isOpen
        };
    }

    public render = (): React.ReactElement<IModalProps> => {
        const { children, isOpen, title } = this.props;

        return (<div className={styles.Modal}>
            {(isOpen) && (<div className={styles.ModalOverlay} onClick={this.onClose}>
                <div className={styles.ModalBox} onClick={this.onBoxClick}>
                    <div className={styles.ModalBoxHeader}>
                        <div className={styles.ModalBoxHeaderTitle}>{title}</div>
                        <div className={styles.ModalBoxHeaderClose} onClick={this.onClose} />
                    </div>
                    <div className={styles.ModalBoxContent}>
                        {children}
                    </div>
                </div>
            </div>)}
        </div>);
    }

    private onClose = (): void => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    private onBoxClick: React.MouseEventHandler<HTMLDivElement> | undefined = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        e.stopPropagation();
    }
}