import { EventType } from "../model/EventType";
import { EventValue } from "../model/EventValue";
import IAct from "../model/IAct";
import IBreak from "../model/IBreak";
import JsonToEventValueMapper from "./JsonToEventValueMapper";

export default class JsonToEventValueStringMapper {
    public static _instance: JsonToEventValueStringMapper | null = null;
    public static instance = (): JsonToEventValueStringMapper => {
        if (JsonToEventValueStringMapper._instance == null) {
            JsonToEventValueStringMapper._instance = new JsonToEventValueStringMapper();
        }

        return JsonToEventValueStringMapper._instance;
    }

    public map = (type: EventType, value: EventValue): string => {
        const eventValue: EventValue = JsonToEventValueMapper.instance().map(type, value)

        switch (type) {
            case "act":
                return (eventValue as IAct)?.name;
            case "break":
                return (eventValue as IBreak)?.kind;
            default:
                return "";
        }
    }
}