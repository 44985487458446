import IEvent from "../model/IEvent";
import JsonToEventValueMapper from "./JsonToEventValueMapper";
import JsonToEventValueStringMapper from "./JsonToEventValueStringMapper";

export default class JsonToEventMapper {
    public static _instance: JsonToEventMapper | null = null;
    public static instance = (): JsonToEventMapper => {
        if (JsonToEventMapper._instance == null) {
            JsonToEventMapper._instance = new JsonToEventMapper();
        }

        return JsonToEventMapper._instance;
    }

    public map = (value: any): IEvent => {
        return {
            type: value?.type,
            value: JsonToEventValueMapper.instance().map(value?.type, value?.value),
            valueAsString: () => JsonToEventValueStringMapper.instance().map(value?.type, value?.value)
        } as IEvent
    }
}