import moment from 'moment';

export default class StringToMomentMapper {
    public static _instance: StringToMomentMapper | null = null;
    public static instance = (): StringToMomentMapper => {
        if (StringToMomentMapper._instance == null) {
            StringToMomentMapper._instance = new StringToMomentMapper();
        }

        return StringToMomentMapper._instance;
    }

    public map = (value: string, format: string = "DD.MM.YYYY"): moment.Moment => {
        const m: moment.Moment = moment(value, format);
        return m;
    }
}