import * as linq from 'linq';
import JsonToMemberMapper from '../mappers/JsonToMemberMapper';
import IMember from "../model/IMember";
import BackendService from './BackendService';

export default class MemberService extends BackendService {
    public static _instance: MemberService | null = null;
    public static instance = (): MemberService => {
        if(MemberService._instance == null) {
            MemberService._instance = new MemberService();
        }

        return MemberService._instance;
    }

    public getMembers = async (): Promise<Array<IMember>> => {
        const members: Array<IMember> = await this.performGetJsonRequest<Array<IMember>>("/members", (json) => linq.default.from(json).select(j => JsonToMemberMapper.instance().map(j)).toArray());
        return members;
    }

    public getMember = async (id: string): Promise<IMember> => {
        const member: IMember = await this.performGetJsonRequest<IMember>(`/members/${id}`, (json) => JsonToMemberMapper.instance().map(json));
        return member;
    }

    public getMemberImage = async (id: string): Promise<string | null> => {
        const image: string | null = await this.performGetImageRequest(`/members/${id}/image`);
        return image;
    }
}